import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '.';
import { normalize, schema } from 'normalizr';

export interface VideoItem {
  id: string;
  name: string;
  description: string;
  hashtags: string[];
  videoURL: string;
  likeCount: number;
  commentCount: number;
  playCount: number;
  shareCount: number;
  gameUrl: string;
  liked: boolean;
}

const videoEntity = new schema.Entity('videos');
const cookieFavorites = 'favCookie2';

interface ScreenState {
  activeView: number;
  logged: boolean;
  isAuthenticating: boolean;
  tempVideoId: string;
  isPlayingGame: boolean;
  currentGameURL: string;
  videos: {
    entities: {
      videos: {
        [id: string]: VideoItem;
      };
    };
    result: string[];
  };
  isFavorite: boolean;
}

function shuffle(array:string[]) {
  let currentIndex = array.length,  randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}

const initialState: ScreenState = {
  activeView: 0,
  logged: false,
  isAuthenticating: false,
  tempVideoId: '',
  isPlayingGame: false,
  currentGameURL: '',
  videos: normalize(shuffle(require('../assets/json/videos.json')), [videoEntity]),
  isFavorite: false,
};

//console.log(initialState);

export const screenSlice = createSlice({
  name: 'screen',
  initialState,
  reducers: {
    playGame: (state, action: PayloadAction<string>) => {
      if(state.logged) {
        const video = state.videos.entities.videos[action.payload];
        state.isPlayingGame = true;
        state.currentGameURL = video.gameUrl;
        video.playCount += 1;
      } else {
        state.isAuthenticating = true;
        state.tempVideoId = action.payload;
      }
      //window.location.href = video.gameUrl;
    },
    stopGame: (state) => {
      state.isPlayingGame = false;
      state.currentGameURL = '';
    },
    likeVideo: (state, action: PayloadAction<string>) => {
      const video = state.videos.entities.videos[action.payload];
      if (!video.liked) {
        video.liked = true;
        video.likeCount += 1;
        var favorites = JSON.parse(localStorage.getItem(cookieFavorites) || '[]');
        if(!favorites.includes(video.id)) {
          favorites.push(video.id);
          localStorage.setItem(cookieFavorites,JSON.stringify(favorites)); 
        }
      }
    },
    unlikeVideo: (state, action: PayloadAction<string>) => {
      const video = state.videos.entities.videos[action.payload];
      if (video.liked) {
        video.liked = false;
        video.likeCount -= 1;
        var favorites = JSON.parse(localStorage.getItem(cookieFavorites) || '[]');
        favorites = favorites.filter(item => item !== video.id)
        localStorage.setItem(cookieFavorites,JSON.stringify(favorites));
      }
    },
    setActiveView: (state, action: PayloadAction<number>) => {
      state.activeView = action.payload;
    },
    loginUser: (state) => {
      state.isAuthenticating = false;
      state.logged = true;
    },
    listFavorite: (state) => {
      var favorites = JSON.parse(localStorage.getItem(cookieFavorites) || '[]');
      if(favorites.length>0) {
        state.isFavorite = true;
        state.videos.result = state.videos.result.filter(id => favorites.includes(id));
        state.videos.entities.videos = Object.fromEntries(
          Object.entries(state.videos.entities.videos)
            .filter(([id]) => favorites.includes(id))
        );
      }
    },
    listMain: (state) => {
      state.isFavorite = false;
      state.videos = normalize(shuffle(require('../assets/json/videos.json')), [videoEntity]);

    }
  },
});

export const { playGame, stopGame, likeVideo, unlikeVideo, setActiveView, loginUser, listFavorite, listMain } = screenSlice.actions;

// Selectors
export const selectGameState = (state: RootState) => state.screen.isPlayingGame;
export const selectGameURL = (state: RootState) => state.screen.currentGameURL;
export const selectVideos = (state: RootState) => state.screen.videos.entities.videos;
export const selectSortedVideoIds = (state: RootState) => state.screen.videos.result;
export const selectActiveView = (state: RootState) => state.screen.activeView;
export const selectAuthenticating = (state: RootState) => state.screen.isAuthenticating;
export const selectTempVideoId = (state: RootState) => state.screen.tempVideoId;
export const selectFavorites = () => JSON.parse(localStorage.getItem(cookieFavorites) || '[]');
export const selectIsFavorite = (state: RootState) => state.screen.isFavorite;
export const selectActiveVideoId = createSelector(
  selectSortedVideoIds,
  selectActiveView,
  (sortedIds, activeView) => sortedIds[activeView]
);

export default screenSlice.reducer;
